import styled from "styled-components";

export const Date = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 23px;
  margin-top: 16px;
  color: #4d5765;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 7px 36px 0px rgba(203, 203, 203, 0.25);
  &:hover {
    opacity: 0.5;
    transform: translateY(10px);
    transition: 0.5s ease;
  }
`;

export const MainCard = styled.div`
  @media (max-width: 1920px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
  @media (max-width: 1536px) {
    flex: 0 0 calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
  @media (max-width: 1200px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(33.33% - 14px);
    max-width: calc(33.33% - 14px);
  }
  @media (max-width: 900px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(50% - 14px);
    max-width: calc(50% - 14px);
  }
  @media (max-width: 600px) {
    // margin: 0 7px 15px;
    flex: 0 0 calc(100% - 14px);
    max-width: calc(100% - 14px);
  }
`;
