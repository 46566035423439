import { Pause, PlayArrow, Stop } from "@mui/icons-material";
import VolumeMuteOutlinedIcon from "@mui/icons-material/VolumeMuteOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import { IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";

interface AudioProps {
  source: string;
  isPlayed: boolean;
  optionKey: string;
  isPlaying: string | null;
  setPlaying: (key: string) => void;
}

export default function Audio({
  source,
  isPlayed,
  optionKey,
  isPlaying,
  setPlaying,
}: AudioProps) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const wrapperRef = useRef<HTMLElement>(null);
  const [canPlay, setCanPlay] = useState(isPlayed);
  const [src, setSrc] = useState<string>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const resultId = useAppSelector((state) => state.result?.result?.id);
  const questionId = location.pathname.split("/")[2];
  const playtime = useAppSelector(
    (state) => state.result?.result?.exam?.audioPlayType
  );

  useEffect(() => {
    setSrc(source);
    setCanPlay(isPlaying === optionKey ? false : isPlayed);
    if (audioRef.current && isPlaying !== optionKey) {
      audioRef.current.load();
      audioRef.current.pause();
    }
  }, [src, source, isPlayed, isPlaying, optionKey]);

  useEffect(() => {
    if (isPlaying === optionKey) {
      audioRef.current?.play();
    }

    if (isPlaying !== optionKey) {
      audioRef.current?.pause();
    }
  }, [isPlaying, optionKey, canPlay]);

  const playAudioHandler = async () => {
    try {
      if (!audioRef.current) return;
      const count = playtime === "one_time" ? 1 : 2;
      for (let i = 1; i < count; i++) {
        audioRef.current.play();
        await new Promise((resolve) => {
          audioRef.current?.addEventListener(
            "ended",
            () => {
              resolve(null);
            },
            { once: true }
          );
        });
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(null);
          }, 3000);
        });
      }

      setCanPlay(true);
      setPlaying(optionKey);
      await apiService.updateAudio(dispatch, {
        resultId,
        [optionKey]: true,
        questionId,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <span
      style={{
        cursor: canPlay ? "pointer" : "disabled",
        zIndex: "100",
        marginLeft: "10px",
        padding: "3px 10px",
      }}
      ref={wrapperRef}
    >
      {optionKey !== isPlaying ? (
        <IconButton onClick={playAudioHandler}>
          <PlayArrow
            style={{
              color: "#595959",
            }}
            fontSize="large"
            width={35}
            height={35}
          />
          <audio ref={audioRef}>
            <source src={src} />
            Your browser does not support the audio element.
          </audio>
        </IconButton>
      ) : (
        <IconButton>
          <Pause
            style={{
              color: "#595959",
            }}
            fontSize="large"
            width={35}
            height={35}
          />
          <audio ref={audioRef}>
            <source src={src} />
            Your browser does not support the audio element.
          </audio>
        </IconButton>
      )}
    </span>
  );
}
