import {
  Dialog,
  RadioGroup,
  useMediaQuery,
  Button as MuiButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Option } from "src/common/theme";
import { ToastOptions, toast } from "react-toastify";
import { AnswerOption } from "src/common/answerOption";
import Audio from "src/common/answerOption/audio";
import {
  H6,
  Box,
  Stack,
  Button,
  NextPreviewWrapper,
  SingleQuestionWrapper,
} from "src/common/theme/index";
import { QuestionEnum } from "src/model/modelSet";
import { QuestionAns } from "src/model/result";
import dontMoveGif from "../../../../../common/images/dont_move_head.webp";
import rotateMsgImage from "../../../../../common/images/rotate.jpg";
import { getTargetedURL } from "src/page-components/home-page/publication";

interface SingleQuestionProps {
  question: QuestionAns;
  activeHandler: (step: number) => void;
  nextQuestion: () => void;
  prevQuestion: () => void;
  handlePlaying: (key: string) => void;
  isPlaying: string | null;
  currentValue: string;
  handleChange: (value: string, questionId: string) => void;
  resetPlaying: () => void;
  submit: () => void;
  uploadingAnswer: boolean;
}

function splitString(str: any) {
  const arr: any = [];
  if (str.includes("//")) {
    return str.split("//");
  } else {
    arr.push(str);
    return arr;
    // return [str];
  }
}
function removeHtmlTags(str: any) {
  return str.replace(/<[^>]*>/g, "");
}

function toastProps({ background }: { background: string }): ToastOptions<{}> {
  return {
    style: { background },
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    draggable: true,
  };
}

export default function SingleQuestion({
  question,
  activeHandler,
  nextQuestion,
  prevQuestion,
  isPlaying,
  handlePlaying,
  currentValue,
  handleChange,
  resetPlaying,
  submit,
  uploadingAnswer,
}: // questionAnswerLength,
SingleQuestionProps) {
  const mobileView = useMediaQuery("(max-width:500px)");

  const notify = () =>
    toast(
      <div
        style={{
          display: mobileView ? undefined : "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {!mobileView && (
          <>
            <img
              src={dontMoveGif}
              width={200}
              height={120}
              object-fit="contain"
            />
            <br />
          </>
        )}
        <h5 style={{ textAlign: "center" }}>कृपया टाउको नहल्लाउनुहोस् !</h5>
      </div>,

      toastProps({ background: "#e5f4fa" })
    );

  const showRotaionAlert = () => {
    toast(
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <>
          <img
            src={rotateMsgImage}
            alt=""
            width={"80%"}
            height={100}
            object-fit="cover"
          />
          <br />
        </>

        <h5 style={{ textAlign: "left" }}>
          Please turn on the auto rotation from setting for better UI !
        </h5>
      </div>,
      toastProps({ background: "white" })
    );
  };
  const [imageHoverStatus, setImageHoverStatus] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      notify();
    }, 180000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (mobileView) {
      showRotaionAlert();
    }
  }, [mobileView]);

  return (
    <div className="orientation">
      <SingleQuestionWrapper>
        <Box>
          <H6 style={{ fontWeight: "400", fontSize: "16px" }}>
            {question.questionGroup?.min}-{question.questionGroup?.max}.
            {question.questionGroup?.title}
          </H6>
          <br />

          <H6 style={{ fontWeight: "400" }}>
            {question.position}.{" "}
            {question.type === "image" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: splitString(question.question)[0],
                }}
              ></div>
            ) : !question.question.includes("//") ? null : (
              <div
                dangerouslySetInnerHTML={{
                  __html: splitString(question.question)[0],
                }}
              ></div>
            )}
          </H6>

          <div
            style={{
              border: "1px dashed #828282",
              padding: " 10px",
              borderRadius: "10px",
            }}
          >
            {question.type === "image" ? (
              <H6 style={{ fontWeight: "400" }}>
                {removeHtmlTags(splitString(question.question)[1] ?? "")}
              </H6>
            ) : !question.question.includes("//") ? (
              <div
                dangerouslySetInnerHTML={{ __html: question.question }}
              ></div>
            ) : (
              <H6 style={{ fontWeight: "400", fontSize: "16px !important" }}>
                {removeHtmlTags(splitString(question.question)[1] ?? "")}
              </H6>
            )}

            <div style={{ width: "100%", maxWidth: "50%" }}>
              {question.type === QuestionEnum.image && question.image ? (
                <div
                  style={{
                    textAlign: "center",
                    margin: "20px",
                    maxWidth: "550px",
                    width: "100%",
                    minHeight: "100px",
                    objectFit: "cover",
                  }}
                  onClick={() => {
                    setImageHoverStatus(question.image ?? "");
                  }}
                >
                  <img
                    height="100%"
                    width="100%"
                    alt="testing"
                    src={getTargetedURL(question.image)}
                  />
                </div>
              ) : question.type === QuestionEnum.audio && question.audio ? (
                <Audio
                  source={getTargetedURL(question.audio!) ?? ""}
                  isPlayed={question.isQuestionAudioPlayed ?? false}
                  isPlaying={isPlaying}
                  optionKey="isQuestionAudioPlayed"
                  setPlaying={handlePlaying}
                />
              ) : (
                question.type === QuestionEnum.imgAudio && (
                  <div>
                    <div
                      style={{
                        textAlign: "center",
                        margin: "20px",
                        maxWidth: "550px",
                        width: "100%",
                        minHeight: "100px",
                        objectFit: "cover",
                      }}
                    >
                      <img
                        height="100%"
                        width="100%"
                        alt="testing"
                        src={getTargetedURL(question.image!)}
                      />
                    </div>
                    <div style={{ background: "#D9D9DC", borderRadius: "8px" }}>
                      <Audio
                        source={getTargetedURL(question.audio!) ?? ""}
                        isPlayed={question.isQuestionAudioPlayed ?? false}
                        isPlaying={isPlaying}
                        optionKey="isQuestionAudioPlayed"
                        setPlaying={handlePlaying}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <br />
        </Box>
        <Stack>
          <RadioGroup
            style={{
              rowGap: "18px",
              flexDirection:
                question.option_type === "image" ? "row" : "column",
              justifyContent: "space-between",
              fontSize: "16px !important",
            }}
          >
            {question &&
              Object.keys(question)
                .filter(
                  (key) =>
                    key.split("_")[0] === "option" && key !== "option_type"
                )
                .map((key, index) => (
                  <Option
                    key={key}
                    style={{
                      background:
                        currentValue === key && !uploadingAnswer
                          ? "#cccccc"
                          : uploadingAnswer && currentValue === key
                          ? "#DBE2E9"
                          : "",
                      color: currentValue === key ? "#ffffff" : "",
                      flex:
                        question.option_type === "image"
                          ? "0 0 calc(50% - 10px)"
                          : "100%",
                      maxWidth:
                        question.option_type === "image"
                          ? "calc(50% - 10px)"
                          : "100%",
                      position: "relative",
                    }}
                  >
                    <AnswerOption
                      key={key}
                      type={question.option_type}
                      index={index + 1}
                      label={(question as any)[key]}
                      isChecked={currentValue === key}
                      source={(question as any)[key]}
                      clicked={() => {
                        if (!uploadingAnswer) {
                          handleChange(key, question.id);
                        }
                      }}
                      isPlayed={
                        (question as any)[
                          `isOption_${key.split("_")[1]}_AudioPlayed`
                        ]
                      }
                      isPlaying={isPlaying}
                      setPlaying={handlePlaying}
                      optionKey={`isOption_${key.split("_")[1]}_AudioPlayed`}
                    />
                  </Option>
                ))}
          </RadioGroup>
        </Stack>
      </SingleQuestionWrapper>
      <NextPreviewWrapper>
        {question.position !== 1 && (
          <Button
            onClick={prevQuestion}
            style={{
              background: "grey",
              border: "none",
              color: "white",
              cursor: cursor(uploadingAnswer),
            }}
            disabled={uploadingAnswer}
          >
            आघिग्लो
          </Button>
        )}

        <Button
          onClick={() => {
            activeHandler(1);
            resetPlaying();
          }}
          style={{
            position: "sticky",
            bottom: "0",
            color: "black",
            background: "#fff",
            border: "1px solid grey",
            cursor: cursor(uploadingAnswer),
          }}
          disabled={uploadingAnswer}
        >
          सबै प्रस्नहरु
        </Button>
        {question.position !== 40 && (
          <Button
            onClick={nextQuestion}
            style={{
              background: "#0D5EF4",
              border: "none",
              color: "white",
              cursor: cursor(uploadingAnswer),
            }}
            disabled={uploadingAnswer}
          >
            {uploadingAnswer ? "प्रतिक्षा गर्नुहोस" : " अर्को"}
          </Button>
        )}
        {question.position === 40 && (
          <Button onClick={submit}>SUBMIT ANSWER</Button>
        )}

        <Dialog open={imageHoverStatus ? true : false}>
          <img
            height="100%"
            width="100%"
            alt="testing"
            src={getTargetedURL(question.image!)}
          />
          <MuiButton
            variant="contained"
            onClick={() => {
              setImageHoverStatus("");
            }}
          >
            Close
          </MuiButton>
        </Dialog>
      </NextPreviewWrapper>
    </div>
  );
}

const cursor = (loading: boolean) => {
  if (loading) return "not-allowed";
  return "pointer";
};
