import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, FormControl, RadioGroup } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { AnswerOption } from "src/common/answerOption";
import angelKoreanLogo from "src/common/images/black-logo.png";
import { H3, H4, H6, Option } from "src/common/theme/index";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";
import { QuestionEnum } from "src/model/modelSet";
import { getTargetedURL } from "src/page-components/home-page/publication";

function Review() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const review = useAppSelector((state) => state.review.review);
  const user = useAppSelector((state) => state.student.profile);
  const [isPlaying, setIsPlaying] = useState<string | null>(null);
  const { id } = useParams();

  const handlePlaying = (key: string) => {
    setIsPlaying(key);
  };

  const { data } = useQuery([dispatch, id], () => {
    if (id) {
      apiService.getReview(dispatch, id);
    }
  });

  const handleWhiteSpace = (d: any, key: any) => {
    // console.log(d.option_A);
    if (d.option_A?.length > 30) {
      return "normal";
    } else {
      return "nowrap";
    }
  };

  const handleBtnColor = (d: any, key: any) => {
    if (d.correct_answer === key && d.selectedAnswer === d.correct_answer) {
      return "#04AA6D";
    } else if (
      d.selectedAnswer === key &&
      d.selectedAnswer !== d.correct_answer
    ) {
      return "#e8b0c6";
    } else if (d.selectedAnswer && d.correct_answer === key) {
      return "#9deba2";
    } else {
      return "none";
    }
  };

  return (
    <div
      style={{
        background: "#F2F6F7",
        padding: "0 0 40px 0",
      }}
    >
      <div
        style={{
          height: "auto",
          padding: "10px 0",
          textAlign: "center",
          background: "#0A2D6F",
          width: "100%",
        }}
      >
        <H3 style={{ color: "#FFFFFF" }}>
          {user && user.role === "student" ? (
            <Button sx={{ color: "white" }} onClick={() => navigate("/mytest")}>
              <ArrowBackIcon sx={{ height: "40px", width: "100px" }} />
            </Button>
          ) : (
            <Button
              sx={{ color: "white" }}
              onClick={() => navigate("/tablet-mode")}
            >
              <ArrowBackIcon sx={{ height: "40px", width: "100px" }} />
            </Button>
          )}
          Review your test
        </H3>
      </div>

      <div
        style={{
          textAlign: "center",
          position: "fixed",
          opacity: ".1",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <H4>ANGEL KOREAN BHASA PVT</H4>
        <img src={angelKoreanLogo} alt="aangel korean logo" width="80%" />
      </div>

      <RootWrapper>
        {review &&
          review?.questionAns.map((reiview, index) => {
            return (
              <div
                style={{
                  border: "1px solid grey",
                  borderRadius: "4px",
                  padding: "10px 10px 10px 20px",
                  marginTop: "20px",
                  zIndex: "-1",
                }}
              >
                <div style={{ margin: "20px 0" }}>
                  <H6 style={{ fontWeight: "400" }}>
                    {reiview.questionGroup.min}-{reiview.questionGroup.max}{" "}
                    {reiview.questionGroup.title}
                  </H6>

                  <H6
                    style={{
                      fontWeight: "400",
                      marginTop: "8px",
                    }}
                  >
                    {reiview.position} .
                    <div
                      style={{
                        padding: "0 20px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: reiview.question,
                      }}
                    ></div>
                  </H6>

                  {reiview.type === QuestionEnum.image && reiview.image ? (
                    <div
                      style={{
                        textAlign: "center",
                        margin: "20px",
                        height: "100px",
                        maxWidth: "250px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    >
                      <img
                        style={{ height: "100%", maxWidth: "100%" }}
                        alt="testing"
                        src={getTargetedURL(reiview.image)}
                      />
                    </div>
                  ) : reiview.type === QuestionEnum.audio && reiview.audio ? (
                    <audio controls>
                      <source src={reiview.audio!} type="audio/ogg" />
                      Your browser does not support the audio element.
                    </audio>
                  ) : null}
                </div>

                {/* -----------------This is option---------------------- */}

                <FormControl
                  style={{
                    flex: "0 0 50%",
                    padding: "0 15px 0 30px",
                  }}
                >
                  <RadioGroup
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      gap: "15px",
                      width: "100%",
                      maxWidth: "1000px",
                    }}
                  >
                    {reiview &&
                      Object.keys(reiview)
                        .filter(
                          (key) =>
                            key.split("_")[0] === "option" &&
                            key !== "option_type"
                        )
                        .map((key, index) => (
                          <Option
                            key={key}
                            style={{
                              background: handleBtnColor(reiview, key),
                              whiteSpace: handleWhiteSpace(reiview, key),
                              flex:
                                reiview.option_type === "image"
                                  ? "0 0 calc(60% - 10px)"
                                  : "100%",
                              minWidth:
                                reiview.option_type === "image"
                                  ? "calc(60% - 10px)"
                                  : "100%",
                              position: "relative",
                            }}
                          >
                            <AnswerOption
                              key={key}
                              isPlaying={isPlaying}
                              setPlaying={handlePlaying}
                              type={reiview.option_type}
                              index={index + 1}
                              label={(reiview as any)[key]}
                              isChecked={true}
                              source={(reiview as any)[key]}
                              isPlayed={
                                (reiview as any)[
                                  `isOption_${key.split("_")[1]}_AudioPlayed`
                                ]
                              }
                              optionKey={`isOption_${
                                key.split("_")[1]
                              }_AudioPlayed`}
                            />
                          </Option>
                        ))}
                  </RadioGroup>
                </FormControl>
              </div>
            );
          })}
      </RootWrapper>
    </div>
  );
}

export default Review;
