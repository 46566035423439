import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import "../../index.css";
import TextField from "src/common/input-field/TextField";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { PhysicalFormWrapper } from "../contact/style/index";

export enum FormCategory {
  Manufacture = "manufacture",
  Agriculture = "agriculture",
  CBT = "CBT",
}

function PhysicalForm() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const classes = useAppSelector((state) => state._class.classes);
  const [value, setValue] = React.useState();

  useEffect(() => {
    apiService.getCourse(dispatch, "physical");
  }, [dispatch]);

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          primary_number: "",
          secondary_number: "",
          dob: "",
          passport_number: "",
          category: "",
          course: "",
          submit: null,
        }}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);
          try {
            await apiService.applyForClass(dispatch, values);

            toast("Successfully applied for class enrollment", {
              type: "success",
            });

            resetForm();
            setLoading(false);
          } catch (error: any) {
            let errorMessage = "Failed to submit form";

            if (error) {
              errorMessage = error.errors
                .map((err: any) => err.message)
                .join(" ");
            }
            toast(errorMessage, {
              type: "error",
            });

            setLoading(false);

            // setModal(false);
          }
          // const res = await apiService.getCourse(values);
          // res?.data && navigate("/");
          // } catch (error) {
          //   enqueueSnackbar("Failed to submit form ", {
          //     variant: "error",
          //     anchorOrigin: {
          //       vertical: "top",
          //       horizontal: "right",
          //     },
          //   });
          // }
        }}
      >
        {({
          setFieldValue,
          handleBlur,
          handleChange,
          errors,
          // helperText,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                // border: "1px solid red",
                columnGap: "25px",
                maxWidth: "695px",
              }}
            >
              <TextField
                className="login-form"
                type="text"
                name="name"
                // error={Boolean(touched.name && errors.name)}
                // helperText={touched.name && errors.name}
                onChange={handleChange}
                placeholder="Your Name *"
                value={values.name}
                required
              />
              {/* {errors.name && <p>{errors.name}</p>} */}
              {/* {errors.name ? errors.name : ""} */}
              <TextField
                className="login-form"
                type="text"
                name="email"
                onChange={handleChange}
                placeholder="Email Address*"
                value={values.email}
              />

              {/* <TextField
                className="login-form"
                type="text"
                name="primary_number"
                onChange={handleChange}
                placeholder="Primary Phone Number *"
                value={values.primary_number}
              /> */}

              <TextField
                className="login-form"
                type="number"
                required
                name="secondary_number"
                onChange={handleChange}
                placeholder="Enter Phone Number *"
                value={values.secondary_number}
              />

              <TextField
                className="login-form"
                required
                type="date"
                name="dob"
                // value={new Date(values.dob)}
                onChange={(e) =>
                  setFieldValue("dob", new Date(e.target.value).getTime())
                }
                placeholder="Date of Birth *"
                // value={new Date(values.dob)}
              />

              <TextField
                className="login-form"
                type="text"
                name="passport_number"
                onChange={handleChange}
                placeholder="Password Number *"
                value={values.passport_number}
              />

              {/* <TextField
                className="login-form"
                type="text"
                name="category"
                onChange={handleChange}
                placeholder="Category *"
                value={values.category}
              /> */}

              <select
                required
                className="login-form"
                onChange={(e) => setFieldValue("category", e.target.value)}
              >
                <option value="">Choose Category</option>
                <option value={FormCategory.Manufacture}>Manufacture</option>
                <option value={FormCategory.Agriculture}>Agriculture</option>
                <option value={FormCategory.CBT}>CBT</option>
              </select>

              <select
                required
                className="login-form"
                onChange={(e) => {
                  setFieldValue("course", e.target.value);
                }}
              >
                <option value="">Course</option>
                {classes.map((each: any) => {
                  return <option value={each.id}>{each.name} - Rem.Capacity {each.capacity - (each.count ?? 0)}</option>;
                })}
              </select>
            </div>
            <Button
              variant="contained"
              disabled={loading ? true : false}
              type="submit"
              style={{ width: "200px", height: "55px", marginTop: "50px" }}
            >
              {loading ? "SUBMITTING..." : "SUBMIT"}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default PhysicalForm;
