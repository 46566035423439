import React from "react";
import { Section, Stack, Box, IconWrapper } from "../style/index";
import {
  DialogTitle,
  DialogActions,
  Dialog,
  Button,
  Typography,
} from "@mui/material";
import {
  FacebookSvg,
  TwitterSvg,
  YoutubeSvg,
  LinkedInSvg,
} from "src/common/svg";
import { useNavigate } from "react-router-dom";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { Body1 } from "src/common/theme";

function FirstHeader() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isLogin = localStorage.getItem("token");

  return (
    <RootWrapper>
      <Stack>
        <Section>
          <div
            style={{
              display: "grid",
              placeItems: "center",
              minHeight: "28px",
              minWidth: "75px",
              borderRadius: "50px",
              background: "#f20f10",
              fontSize: "14px",
              color: "#ffffff",
              border: "none",
              // textTransform: "capitalize",
            }}
          >
            Notices
          </div>
          <Body1 color="white">Admission Going On, Hurry To Enroll Now</Body1>
        </Section>
        <Box>
          <IconWrapper>
            <a href="https://www.facebook.com/angelkoreanbhasa" target="_blank">
              {" "}
              <FacebookSvg />
            </a>
            {/* <TwitterSvg /> */}
            {/* <LinkedInSvg /> */}
            <a
              href="https://www.youtube.com/channel/UCRcMIdiOGSOP5nOnsoMQVpQ"
              target="_blank"
            >
              <YoutubeSvg />
            </a>
          </IconWrapper>
          {!isLogin && (
            <Body1
              style={{
                cursor: "pointer",
                borderRight: "1px solid white",
                paddingRight: "12px",
              }}
              color="white"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Body1>
          )}
          {!isLogin && (
            <Body1
              style={{ cursor: "pointer" }}
              color="white"
              onClick={() => navigate("/signup")}
            >
              Register
            </Body1>
          )}
          {isLogin && (
            <>
              <Body1
                onClick={() => navigate("/mytest")}
                color="white"
                style={{
                  cursor: "pointer",
                  borderRight: "1px solid white",
                  paddingRight: "12px",
                }}
              >
                Profile
              </Body1>

              <Body1
                onClick={handleClickOpen}
                color="white"
                style={{ cursor: "pointer" }}
              >
                Log Out
              </Body1>

              <Typography>v1.2.1</Typography>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Are you sure want to logout?</DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose} variant="outlined">
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      localStorage.removeItem("token");
                      navigate("/login");
                      window.location.reload();
                    }}
                    style={{
                      background: "#00306E",
                      color: "#ffffff",
                      marginRight: "23px",
                    }}
                  >
                    YES
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Box>
      </Stack>
    </RootWrapper>
  );
}

export default FirstHeader;
