import { configureStore } from "@reduxjs/toolkit";
import ReduxThunk from "redux-thunk";
import { classSlice } from "src/store/reducers/classSlice";
import modelSetSlice from "./reducers/modelSetSlice";
import studentProfileSlice from "./reducers/studentProfileSlice";
import resultSlice from "./reducers/resultSlice";
import reviewSlice from "./reducers/reviewSlilce";
import sliderSlice from "./reducers/slider";
import { blogSlice } from "./reducers/blog";
import publicationSlice from "./reducers/publication";
import downloadSlice from "./reducers/download";
import tabletModeSlice from "./reducers/tabletModeSlice";
import packageSlice from "./reducers/packages";

export const middlewares = [ReduxThunk];

export const store = configureStore({
  reducer: {
    _class: classSlice.reducer,
    result: resultSlice.reducer,
    modalSet: modelSetSlice.reducer,
    student: studentProfileSlice.reducer,
    review: reviewSlice.reducer,
    slider: sliderSlice.reducer,
    blog: blogSlice.reducer,
    publication: publicationSlice.reducer,
    download: downloadSlice.reducer,
    tablet_mode: tabletModeSlice.reducer,
    package: packageSlice.reducer
  },
  middleware: middlewares,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
