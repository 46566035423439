import { getTargetedURL } from "src/page-components/home-page/publication";

interface ImageProps {
  source?: string;
  label?: string;
}

export default function Image({ source, label }: ImageProps) {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "10px",
        // border: '2px solid red',
        maxWidth: "calc(100% - 35px)",
        flex: "calc(100% - 35px)",
      }}
    >
      <img
        style={{
          objectFit: "contain",
          width: "100%",
          height: "100%",
        }}
        alt={label}
        src={source && getTargetedURL(source)}
      />
    </div>
  );
}
