import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Slider } from "../../model/slider";

interface SliderResource {
  total: number;
  slider: Slider[];
}

const initialState: SliderResource = {
  total: 0,
  slider: [],
};

export const sliderSlice = createSlice({
  name: "slider",
  initialState,
  reducers: {
    getSlider: (state: any, action: any) => {
      return {
        ...state,
        total: state.total,
        slider: action.payload.data,
      };
    },
  },
});

export const { getSlider } = sliderSlice.actions;

export default sliderSlice;
