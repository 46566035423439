import { ResponseUtils } from 'src/types/response/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Package } from "src/model/packages";




interface PackageResource {
    packages: Package[]
}


const initialState: PackageResource = {
    packages: [],
}

export const packageSlice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        getPackages: (state, action: PayloadAction<ResponseUtils<Package>>) => ({
            ...state,
            packages: action.payload.data,

        })
    }

})


export const { getPackages } = packageSlice.actions


export default packageSlice