import About from "./about";
import FirstHeader from "./first-header";
import SecondHeader from "./second-header";
import { Navbar } from "./style/index";

function index() {
  return (
    <div>
      <Navbar>
        <FirstHeader />
      </Navbar>
      <About />
      <Navbar>
        <SecondHeader />
      </Navbar>
    </div>
  );
}

export default index;
