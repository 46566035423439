import { styled } from "@mui/material";
import React from "react";
import { H6 } from "../theme";
import Audio from "./audio";
import Image from "./image";

interface AnswerOptionProps {
  type: string;
  isChecked: boolean;
  label: string;
  index: number;
  value?: any;
  onChange?: any;
  clicked?: any;
  source: string;
  isPlayed?: boolean;
  optionKey: string;
  isPlaying: string | null;
  setPlaying: (key: string) => void;
}

const CircleStyled = styled("span")((props) => ({
  width: "35px",
  height: "30px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 400,
  cursor: "pointer",
  fontSize: "16px",
  fontFamily: props.theme.typography.fontFamily,
  "&:before": {
    content: "' '",
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
  },
}));

export const AnswerOption: React.FC<AnswerOptionProps> = ({
  type,
  isChecked,
  label,
  source,
  isPlayed,
  setPlaying,
  isPlaying,
  index,
  clicked,
  optionKey,
}) => {
  let option: JSX.Element;

  switch (type) {
    case "text":
      option = (
        <H6
          style={{
            padding: "4px 8px",
            fontWeight: "400",
            fontSize: "16px",
          }}
        >
          {label}
        </H6>
      );
      break;
    case "audio":
      option = (
        <Audio
          optionKey={optionKey}
          source={source}
          setPlaying={setPlaying}
          isPlayed={isPlayed ?? false}
          isPlaying={isPlaying}
        />
      );
      break;
    case "image":
      option = <Image source={source} label={label} />;
      break;
    default:
      return null;
  }

  return (
    <div
      style={{
        padding: "7px",
        display: "flex",
        textAlign: "left",
        alignItems: "center",
        width: "100%",
      }}
    >
      <CircleStyled
        onClick={clicked}
        style={{
          background: isChecked ? "#0D5EF4" : "transparent",
          color: isChecked ? "#fff" : "#828282",
          border: `1px solid ${isChecked ? "#0D5EF4" : "#828282"}`,
        }}
      >
        {index}
      </CircleStyled>
      {option}
    </div>
  );
};
