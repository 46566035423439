import axios from "axios";
import { configuration } from 'src/config'
const commonService = {
  googleLogin: async (jwt: string): Promise<string> => {
    try {
      const response = await axios.post(
        `${configuration.commonUrl}/common/googleverify`,
        { jwtToken: jwt }
      );

      return response.data;
    } catch (error) {
      return (error as Error).message;
    }
  },

  uploadProfile: async (formData: FormData): Promise<string> => {
    try {
      const response = await axios.post(
        `${configuration.commonUrl}/common/upload-profile`,
        formData
      );

      return response.data.data;
    } catch (error) {
      return (error as Error).message;
    }
  },
};
export { commonService };