import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { East } from "@mui/icons-material";
import { Body1, H5, H4 } from "src/common/theme";
import { getTargetedURL } from "../../publication";

function ClassType(props: {
  image?: string;
  title: string;
  button: ReactNode;
  info: string;
  paths: string;
  background?: "pink" | "blue";
  color?: "red" | "blue";
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
}) {
  return (
    <div
      style={{
        margin: "50px 0",
        width: "100%",
        textAlign: "center",
        display: "grid",
        placeItems: "center",
        flexDirection: props.flexDirection,
      }}
    >
      <div
        style={{
          zIndex: "1",
          // border: "1px solid",
          width: "100%",
          maxWidth: "400px",
          objectFit: "cover",
        }}
      >
        <img
          src={props?.image && getTargetedURL(props.image)}
          alt="images"
          height="auto"
          width="100%"
        />
      </div>
      <div
        style={{
          maxWidth: "550px",
          minHeight: "300px",
          borderRadius: "15px",
          width: "100%",
          marginTop: "-80px",
          padding: "50px",
          background: props["background"] === "pink" ? "#FFF3F3" : "#EDF5FF",
        }}
      >
        <H5 style={{ paddingTop: "40px" }}>{props.title}</H5>
        <Body1 style={{ paddingTop: "15px" }}>{props.info}</Body1>
        <Link to={props.paths}>
          <Button
            variant="contained"
            endIcon={<East />}
            sx={{
              marginTop: "20px",
              padding: "15px 30px",
              background: props["color"] === "red" ? "#F20F10" : "#0D5EF4",
            }}
          >
            {props.button}
          </Button>
        </Link>
      </div>
    </div>
  );
}

ClassType.defaultProps = {
  props: {
    flexDirection: "column",
  },
};

export default ClassType;
