import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { theme } from "src/common/theme";
import { store } from "src/store";
import { ThemeProvider } from "styled-components";
import App from "./App";
import "./index.css";

import "swiper/css";

import "swiper/css/scrollbar";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const client = new QueryClient();

root.render(
  <GoogleOAuthProvider clientId="585825640802-4canro8rvuj3k4ar930l9u4jnb5ivv5a.apps.googleusercontent.com">
    <QueryClientProvider client={client}>
      <ToastContainer limit={1} />
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  </GoogleOAuthProvider>
);
