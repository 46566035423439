import React, { useEffect, useState } from "react";
import Header from "src/common/layouts/header/index";
import Footer from "src/common/layouts/footer/index";
import Login from "./page-components/login-page";
import About from "src/page-components/about/index";
import Classes from "src/page-components/class-group/classes/index";
import Contact from "src/page-components/contact/index";
import Exam from "src/page-components/Exam/index";
import { useAppDispatch } from "src/hooks/hooks";
import ExamQuestion from "src/page-components/Exam/Exam-Question";
import Profile from "src/page-components/my-profile/Profile";
import ExamineersInfo from "src/page-components/Examineers-Info/index";
import SignUp from "src/page-components/sign-up-form/";
import HomePage from "src/page-components/home-page/index";
import SuccessPay from "./page-components/success";
import Downloadable from "./page-components/downloadable";
import Packages from "./page-components/packages";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import TabletMode from "./page-components/tablet-mode/tablet-mode-header";
import OnlineClassForm from "src/page-components/online-class";
import PhysicalClassForm from "src/page-components/physical-class";
import { useAppSelector } from "./hooks/hooks";
import Payment from "src/page-components/payment/index";
import Fail from "./page-components/fail/Fail";
import Page404 from "./page-components/404/Page404";
import Stats from "src/page-components/test-completed/Stats";
import Review from "./page-components/review";
import { apiService } from "./http/api-service";
import PagePrivacyPolicy from "./page-components/others/privacy";
import PageTermsOfUse from "./page-components/others/terms";

export const HeaderFooter = ({ children }: { children: any }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

const token = localStorage.getItem("token");

const ProtectedRoute = ({ token, loading }: any) => {
  if (!loading) {
    if (!token) {
      return <Navigate to="/login" replace={true} />;
    } else {
      return <Outlet />;
    }
  } else {
    return (
      <>
        <h3>loading...</h3>
      </>
    );
  }
};

function App() {
  const dispatch = useAppDispatch();
  const [token, setToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const tokenGlobal = useAppSelector((state) => state.student.profile?.name);

  useEffect(() => {
    const fetchProfile = async () => {
      const res = await apiService.getProfile(dispatch);
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    const tokenLocal = localStorage.getItem("token");
    if (tokenLocal) {
      setToken(tokenLocal);
      setLoading(false);
    }
    setLoading(false);
  }, [location.pathname, tokenGlobal]);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/classes" element={<Classes />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/payment" element={<Payment />} /> */}
        <Route path="/download" element={<Downloadable />} />
        {/* <Route path="/packages" element={<Packages />} /> */}

        {/* <Route path="/news" element={<News />} /> */}
        <Route path="/successpay" element={<SuccessPay />} />
        <Route path="/fail" element={<Fail />} />
        <Route element={<ProtectedRoute token={token} loading={loading} />}>
          <Route path="/tablet-mode" element={<TabletMode />} />
          {/* <Route path="/exam" element={<Exam />} /> */}
          <Route path="/examineer" element={<ExamineersInfo />} />
          <Route path="/examroom" element={<ExamQuestion />} />
          <Route path="/examroom/:id" element={<ExamQuestion />} />
          <Route path="/mytest" element={<Profile />} />
          <Route path="/mytest/:id" element={<Review />} />
          <Route path="/stats" element={<Stats />} />

          {/* <Route path="/review" element={<Review />} /> */}
          {/* <Route path="/review/: id" element={<Review />} /> */}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/*" element={<Page404 />} />
        <Route path="/terms-of-use" element={<PageTermsOfUse />} />
        <Route path="/privacy-policy" element={<PagePrivacyPolicy />} />
        <Route path="/physical-class" element={<PhysicalClassForm />} />
        <Route path="/online-class" element={<OnlineClassForm />} />
      </Routes>
    </>
  );
}

export default App;
