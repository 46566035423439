import {
  Button,
  Card,
  Grid,
  Typography,
  Box as MuiBox,
  Chip,
  Dialog,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Body1, Body2, H2, H5, H6 } from "src/common/theme";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";
import { Box, Container, PracticeNowButton, Wrapper } from "../style/index";
import Esewa from "src/common/images/esewa-icon.png";
import Payment from "src/page-components/payment";
import { Package } from "src/model/packages";

export default function PopularModalSets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogin = localStorage.getItem("token");
  const featured = useAppSelector((state) => state.modalSet.exams);
  const packages = useAppSelector((state) => state.package.packages);
  const user = useAppSelector((state) => state.student.profile);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [paymentModelDisplay, setPaymentModel] = useState(false);
  const [packageId, setPackageId] = useState("");
  const [packagePrice, setPackagePrice] = useState(0);

  useEffect(() => {
    apiService.getFeaturedModalSet(dispatch);
    apiService.getPackages(dispatch);
  }, []);

  const getPackagePrice = (packageItem: Package) => {
    return (
      (packageItem.modelsets
        .map((modelset) => modelset.price)
        .reduce((a, b) => a + b, 0) *
        (100 - packageItem.discount)) /
      100
    );
  };

  const onCancelPayementServicesModal = () => {
    setPackageId("");
    setPackagePrice(0);
    setPaymentModel(false);
  };

  return (
    <div
      style={{
        background: "#F3F7FB",
      }}
    >
      <RootWrapper style={{ padding: "50px 0" }}>
        <Box>
          <H5 style={{ marginTop: "10px" }}>UBT Packages</H5>
          <MuiBox>
            {packages && packages.length > 0 ? (
              packages.map((packageItem) => {
                return (
                  <Wrapper key={packageItem.id}>
                    {packageItem && (
                      <Container key={packageItem.id}>
                        <MuiBox sx={{ display: "flex" }}>
                          <Body2
                            style={{
                              background: `${
                                packageItem.manualEmails.includes(
                                  user?.email ?? ""
                                ) ||
                                [
                                  ...packageItem.paidEmails,
                                  ...packageItem.paidEmails,
                                ].includes(user?.email ?? "")
                                  ? "green"
                                  : "blue"
                              }`,
                              padding: "8px 10px",
                              color: "white",
                              width: "80px",
                              minHeight: "40px",
                              textAlign: "center",
                              borderRadius: "20px",
                            }}
                          >
                            {packageItem.modelsets?.length} Sets
                            {[...packageItem.paidEmails].includes(
                              user?.email ?? ""
                            ) ? (
                              <div
                                style={{
                                  width: "fit-content",
                                  display: "inline",
                                  marginTop: "-9px",
                                }}
                              >
                                <img src={Esewa} height={20} />
                              </div>
                            ) : packageItem.manualEmails.includes(
                                user?.email ?? ""
                              ) ? (
                              <Typography
                                sx={{
                                  marginTop: "-5px",
                                  fontSize: "12px",
                                }}
                              >
                                Manual
                              </Typography>
                            ) : null}
                          </Body2>
                        </MuiBox>
                        <Body1>{packageItem.name}</Body1>
                        <H6
                          color="blue"
                          style={{
                            borderBottom: "1px dashed #D0D7E3",
                            paddingBottom: "15px",
                          }}
                        >
                          Rs.{" "}
                          {packageItem.modelsets
                            .map((modelset) => modelset.price)
                            .reduce((a, b) => a + b, 0)}{" "}
                          ({packageItem.discount}% off)
                        </H6>
                        <H6
                          color="blue"
                          style={{
                            borderBottom: "1px dashed #D0D7E3",
                            paddingBottom: "15px",
                          }}
                        >
                          Get it Now For Rs.{" "}
                          {(packageItem.modelsets
                            .map((modelset) => modelset.price)
                            .reduce((a, b) => a + b, 0) *
                            (100 - packageItem.discount)) /
                            100}
                        </H6>

                        <PracticeNowButton
                          style={{
                            background: `${
                              packageItem.manualEmails.includes(
                                user?.email ?? ""
                              ) ||
                              packageItem.paidEmails.includes(user?.email ?? "")
                                ? "green"
                                : "blue"
                            }`,
                          }}
                          onClick={() => {
                            if (!isLogin) {
                              navigate("/login", {
                                state: {
                                  packagePrice: getPackagePrice(packageItem),
                                  packageId: packageItem.id,
                                },
                              });
                            } else if (
                              user &&
                              (packageItem.manualEmails.includes(user.email) ||
                                packageItem.paidEmails.includes(user.email))
                            ) {
                              navigate("/exam");
                            } else {
                              setPackageId(packageItem.id);
                              setPackagePrice(getPackagePrice(packageItem));
                              setPaymentModel(true);
                              // navigate("/payment", {
                              //   state: {
                              //     price: getPackagePrice(packageItem),
                              //     modelSetId: packageItem.id,
                              //   },
                              // });
                            }
                          }}
                        >
                          <Body2 style={{ color: "#FFFFFF" }}>
                            {user &&
                            [
                              ...packageItem.paidEmails,
                              ...packageItem.manualEmails,
                            ].includes(user.email)
                              ? "View Model Sets"
                              : "BOOK NOW"}
                          </Body2>
                        </PracticeNowButton>
                      </Container>
                    )}
                  </Wrapper>
                );
              })
            ) : (
              <MuiBox m={2}>
                <Typography>No packages available</Typography>
              </MuiBox>
            )}
          </MuiBox>

          <H5 style={{ marginTop: "70px" }}>Available Modelsets</H5>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "18px",
            }}
          >
            {featured.map((item, index) => {
              return (
                <Wrapper key={index}>
                  {item.type === "free" && (
                    <Container>
                      <Body2
                        style={{
                          background: `${
                            item.type === "free" ? "red" : "blue"
                          }`,
                          color: "white",
                          width: "60px",
                          height: "24px",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        {item.type.toUpperCase()}
                      </Body2>

                      <Body1 color="grey">{item.title}</Body1>
                      <H5 color="grey">{item.subtitle}</H5>
                      {/* <H6 color="blue">Rs {item.price}</H6> */}
                      <H6
                        color="blue"
                        style={{
                          borderBottom: "1px dashed #D0D7E3",
                          paddingBottom: "15px",
                        }}
                      >
                        {item.model_type}
                      </H6>
                      <PracticeNowButton
                        color={item.type === "free" ? "red" : "blue"}
                        onClick={() => {
                          if (item.type === "free") {
                            isLogin
                              ? navigate("/examineer", {
                                  state: {
                                    modelSetId: item.id,
                                    modelSet: item,
                                  },
                                })
                              : navigate("/login");
                          } else {
                            navigate("/payment", {
                              state: {
                                price: item.price,
                                modelSetId: item.id,
                              },
                            });
                          }
                        }}
                      >
                        <Body2 style={{ color: "#FFFFFF" }}>
                          {item.type === "free" ? "PRACTICE NOW" : "BOOK NOW"}
                        </Body2>
                      </PracticeNowButton>
                    </Container>
                  )}
                </Wrapper>
              );
            })}
          </div>

          <ChoosePaymentMethodAndPay
            paymentModelDisplay={paymentModelDisplay}
            packagePrice={packagePrice}
            packageId={packageId}
            onCancelPayementServicesModal={onCancelPayementServicesModal}
          />

          {/* <div
            style={{
              textAlign: "center",
              marginTop: "50px",
              // border: '1px solid red'
            }}
          >
            <Button
              variant="contained"
              sx={{ padding: "15px 38px" }}
              onClick={() => (isLogin ? navigate("/exam") : navigate("/login"))}
            >
              LEARN MORE
            </Button>
          </div> */}
        </Box>
      </RootWrapper>
    </div>
  );
}

type ChoosePaymentMethodAndPayType = {
  paymentModelDisplay: boolean;
  packagePrice: number;
  packageId: string;
  onCancelPayementServicesModal: any;
};

export function ChoosePaymentMethodAndPay({
  paymentModelDisplay,
  packagePrice,
  packageId,
  onCancelPayementServicesModal,
}: ChoosePaymentMethodAndPayType) {
  return (
    <Dialog open={paymentModelDisplay}>
      <Card sx={{ p: 2 }}>
        <Box>
          <Payment price={packagePrice} modelSetId={packageId} />
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            onCancelPayementServicesModal();
          }}
        >
          Cancel
        </Button>
      </Card>
    </Dialog>
  );
}
