import React, { useEffect, useRef, useState } from "react";
import HeroSection from "./hero-section/index";
import BookClass from "./book-class/index";
import AboutAngelKorean from "./about-angel-korean/index";
import Rating from "./rating/index";
import ModelSet, { ChoosePaymentMethodAndPay } from "./model-set";
import LatestNews from "./latest-news";
import Publication from "./publication";
import YoutubeAbout from "../about/learn/index";
import { HeaderFooter } from "src/App";
import { useAppSelector } from "src/hooks/hooks";
import { useLocation, useNavigate } from "react-router-dom";
function Index() {
  const navigate = useNavigate();

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const { state } = useLocation();

  const scrollToBottom = () => {
    return new Promise<void>((resolve, reject) => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      resolve();
    });
  };

  function openModal() {
    setOpenModal(true);
  }

  const [openModalToChoosePaymentServices, setOpenModal] = useState(false);

  const user = useAppSelector((state: any) => state.student.profile);

  React.useEffect(() => {
    if (user && user.role === "tablet") {
      navigate("/tablet-mode");
    }
  }, [user]);

  useEffect(() => {
    if ((state?.packageId?.length ?? 0) > 0) {
      scrollToBottom().then(() => openModal());
    }
  }, [state?.packageId]);

  const onCancelPayementServicesModal = () => {
    navigate("/", { replace: true });
  };
  return (
    <HeaderFooter>
      <HeroSection />
      {/* <ModelSet /> */}

      <div ref={messagesEndRef}>
        {state?.packageId && (
          <ChoosePaymentMethodAndPay
            packageId={state.packageId}
            packagePrice={state.price}
            paymentModelDisplay={openModalToChoosePaymentServices}
            onCancelPayementServicesModal={onCancelPayementServicesModal}
          />
        )}
      </div>
      <BookClass />
      <YoutubeAbout />
      <Publication />
      <LatestNews />
      <AboutAngelKorean />
      <Rating />
    </HeaderFooter>
  );
}

export default Index;
