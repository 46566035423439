import angelKoreanLogo from "src/common/images/color-logo.jpg";
import { Body1, H6 } from "src/common/theme";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { DateWrapper, Div, Wrapper } from "../style/index";
import KoreanDate from "./korean-date";
import NepalDate from "./nepal-date";
import { getTargetedURL } from "src/page-components/home-page/publication";

function index() {
  return (
    <RootWrapper>
      <Wrapper>
        <Div>
          <div
            style={{
              maxHeight: "81px",
              width: "100%",
              maxWidth: "76px",
              objectFit: "cover",
            }}
          >
            <img
              src={getTargetedURL(angelKoreanLogo)}
              alt="angel korean logo"
              height="100%"
              width="100%"
            />
          </div>
          <div>
            <H6 color="red">ANGEL KOREAN BHASHA PVT</H6>
            <Body1 color="black">Bagbazar, Kathmandu, Nepal</Body1>
            <Body1 color="black">9848254922</Body1>
          </div>
        </Div>
        <DateWrapper>
          <NepalDate />
          <div style={{ borderRight: "1.5px solid #E5E5E5" }} />
          <KoreanDate />
        </DateWrapper>
      </Wrapper>
    </RootWrapper>
  );
}

export default index;
