import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderFooter } from "src/App";
import AngelKoreanLogo from "src/common/images/black-logo.png";
import TextField from "src/common/input-field/TextField";
import { Body1, Body2, H6 } from "src/common/theme";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";
import { Container, Content, FormWrapper, Main, Wrapper } from "./style";

interface UserProps {
  edit?: boolean;
  setModal?: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
}

const Examineers = ({ edit, setModal, id }: UserProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector((state: any) => state.student.profile);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    apiService.getProfile(dispatch);
  }, [dispatch]);

  const startExam = async () => {
    setIsLoading(true);
    if (!(location.state as any).modelSetId) {
      console.log("herere");
      navigate("/examroom");
      setIsLoading(false);
    }
    try {
      const response = await apiService.getExamResult(
        dispatch,
        (location.state as any).modelSetId
      );
      setIsLoading(false);
      localStorage.setItem("modalSetId", (location.state as any).modelSetId!);
      localStorage.setItem("resultId", response!.data.id);
      navigate("/examroom");
    } catch (error) {
      alert("Failed to get exam");
    }
  };

  return (
    <>
      {user.role === "student" ? (
        <HeaderFooter>
          <RootWrapper>
            <Wrapper
              style={{
                border: "1px solid #ecf1f9",
                boxShadow: " 1px 1px 32px 2px rgba(203, 203, 203, 0.25)",
              }}
            >
              <Main>
                {/* ---------------------------Title section---------------------- */}
                <Content>
                  <H6 color="blue">Examineers Information</H6>
                  <Body1
                    style={{
                      borderTop: "2px solid #E6E6E6",
                      paddingTop: "8px",
                    }}
                  >
                    Exam :&nbsp;
                    <span style={{ color: "#000000", fontWeight: "450" }}>
                      {(location.state as any).modelSet.title}
                    </span>
                  </Body1>
                </Content>

                {/* ---------------------------User info Section---------------------- */}

                <Container>
                  <FormWrapper>
                    <div
                      style={{ border: "1px solid #E6E6E6", padding: "8px" }}
                    >
                      <img
                        src={AngelKoreanLogo}
                        width={150}
                        height={160}
                        style={{ objectFit: "cover", marginTop: "10px" }}
                      />
                    </div>
                    <div>
                      <Body2 style={{ padding: "9px 0 2px 0" }}>
                        Examinee’s name
                      </Body2>
                      <TextField
                        type="text"
                        name="name"
                        className="Examineers-Info"
                        disabled
                        value={user ? user.name : ""}
                      />
                      <Body2 style={{ paddingBottom: "2px" }}>
                        Examinee’s email
                      </Body2>
                      <TextField
                        type="text"
                        className="Examineers-Info"
                        name="email"
                        disabled
                        value={user ? user.email : ""}
                      />
                    </div>
                  </FormWrapper>
                </Container>
                <div>
                  <Button
                    type="submit"
                    onClick={startExam}
                    variant="contained"
                    sx={{ padding: "10px 30px" }}
                    disabled={isLoading}
                  >
                    {isLoading ? <CircularProgress /> : "Start Exam"}
                  </Button>
                </div>
              </Main>
            </Wrapper>
          </RootWrapper>
        </HeaderFooter>
      ) : (
        <RootWrapper>
          <Wrapper
            style={{
              border: "1px solid #ecf1f9",
              boxShadow: " 1px 1px 32px 2px rgba(203, 203, 203, 0.25)",
            }}
          >
            <Main>
              {/* ---------------------------Title section---------------------- */}
              <Content>
                <H6 color="blue">Examineers Information</H6>
                <Body1
                  style={{
                    borderTop: "2px solid #E6E6E6",
                    paddingTop: "8px",
                  }}
                >
                  Exam :&nbsp;
                  <span style={{ color: "#000000", fontWeight: "450" }}>
                    {(location.state as any).modelSet.title}
                  </span>
                </Body1>
              </Content>

              {/* ---------------------------User info Section---------------------- */}

              <Container>
                <FormWrapper>
                  <div style={{ border: "1px solid #E6E6E6", padding: "8px" }}>
                    <img
                      src={AngelKoreanLogo}
                      width={150}
                      height={160}
                      style={{ objectFit: "cover", marginTop: "10px" }}
                    />
                  </div>
                  <div>
                    <Body2 style={{ padding: "9px 0 2px 0" }}>
                      Examinee’s name
                    </Body2>
                    <TextField
                      type="text"
                      name="name"
                      className="Examineers-Info"
                      disabled
                      value={user ? user.name : ""}
                    />
                    <Body2 style={{ paddingBottom: "2px" }}>
                      Examinee’s email
                    </Body2>
                    <TextField
                      type="text"
                      className="Examineers-Info"
                      name="email"
                      disabled
                      value={user ? user.email : ""}
                    />
                  </div>
                </FormWrapper>
              </Container>
              <div>
                <Button
                  type="submit"
                  onClick={startExam}
                  variant="contained"
                  sx={{ padding: "10px 30px" }}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Start Exam"}
                </Button>
              </div>
            </Main>
          </Wrapper>
        </RootWrapper>
      )}
    </>
  );
};

export default Examineers;
