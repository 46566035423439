import React from "react";
import { Link } from "react-router-dom";
import { Body2, H5 } from "src/common/theme/index";
import { CardWrapper, MainCard, Date } from "./style";
import { getTargetedURL } from "src/page-components/home-page/publication";

interface CardProps {
  image: string;
  title?: string;
  subTitle?: string;
  date?: string;
  url?: string;
}

// const style = {
//   color: "#000000",
//   "::after": {
//     position: "absolute",
//     display: "block",
//   },
// };

function index({ image, title, subTitle, date, url }: CardProps) {
  return (
    <>
      {url ? (
        <MainCard
          onClick={() => window.open(url)}
          style={{
            cursor: "pointer",
          }}
        >
          <CardWrapper>
            <div
              style={{
                maxWidth: "384px",
                height: "280px",
                objectFit: "cover",
                // margin: "30px 30px 5px 30px",
              }}
            >
              <img
                src={getTargetedURL(image)}
                alt="images"
                width="100%"
                height="100%"
              />
            </div>
            <H5>{title}</H5>
            {date && <Date>{date}</Date>}
            <Body2
              color="blue"
              style={{
                borderTop: "1px solid #EFF1F5",
                padding: "10px 30px 0 0",
                fontWeight: "500",
              }}
            >
              {url && (
                <a href={subTitle} target={"_"}>
                  READ MORE DETAILS
                </a>
              )}
            </Body2>
          </CardWrapper>
        </MainCard>
      ) : (
        <MainCard>
          {/* <CardWrapper> */}
          <div
            style={{
              maxWidth: "384px",
              height: "280px",
              objectFit: "cover",
              // margin: "30px 30px 5px 30px",
            }}
          >
            <img
              src={getTargetedURL(image)}
              alt="images"
              width="100%"
              height="100%"
            />
          </div>
          <H5>{title}</H5>
          {date && <Date>{date}</Date>}
          {/* </CardWrapper> */}
        </MainCard>
      )}
    </>
  );
}

export default index;
