import React from "react";
import { Typography, Main, Div, Wrapper, PaymentWrapper } from "./style/index";
import { Body1, H2, H4, H3, UnderLine, H6 } from "src/common/theme";
import TextField from "src/common/input-field/TextField";
import { Button, Card } from "@mui/material";
import EsewaIcon from "src/common/images/esewa-icon.png";
import KhaltiIcon from "src/common/images/khalti-icon.png";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { HeaderFooter } from "src/App";
import { RootWrapper } from "src/common/wrapper/root-wrapper";

const Payment = ({ price, modelSetId }: { price: any; modelSetId: any }) => {
  // const { price, modelSetId } = useLocation().state as any;
  return (
    // <HeaderFooter>
    //   <RootWrapper>
    //     <Main>

    //     </Main>
    //   </RootWrapper>
    // </HeaderFooter>
    <Wrapper>
      <H6 style={{ marginTop: "20px  " }}>Choose your payment method</H6>
      <UnderLine />

      <PaymentWrapper>
        {/* --------------------------------------This is E-swea Payment Section-------------- */}
        <Button
          style={{
            borderRadius: "4px",
            height: "80px",
            width: "60px",
            display: "grid",
            placeItems: "center",
            marginBottom: "30px",
            marginLeft: "10px",
          }}
        >
          <form
            action="https://esewa.com.np/epay/main"
            method="POST"
            style={{ padding: "0px" }}
          >
            <input value={price} name="tAmt" type="hidden" />
            <input value={price} name="amt" type="hidden" />
            <input value="0" name="txAmt" type="hidden" />
            <input value="0" name="psc" type="hidden" />
            <input value="0" name="pdc" type="hidden" />
            <input
              value={process.env.REACT_APP_MERCHANT_ID}
              name="scd"
              type="hidden"
            />
            <input
              value={`${uuidv4()}_${modelSetId}`}
              name="pid"
              type="hidden"
            />
            <input
              value={`${process.env.REACT_APP_FRONTEND_URL}/successpay`}
              type="hidden"
              name="su"
            />
            <input
              value={`${process.env.REACT_APP_FRONTEND_URL}/exam`}
              type="hidden"
              name="fu"
            />
            <Card sx={{ p: 1 }}>
              <button
                type="submit"
                style={{
                  background: "#FFFFFF",
                  height: "100%",
                  width: "100%",
                  border: "none",
                }}
              >
                <img
                  src={EsewaIcon}
                  style={{
                    cursor: "pointer",
                  }}
                  alt="esewa-image"
                  height={70}
                />
              </button>
            </Card>
          </form>
        </Button>
        {/* --------------------------------------This is Khalti Payment Section-------------- */}

        {/* <div
      style={{
        borderRadius: "4px",
        height: "130px",
        background: "#FFFFFF",
        width: "270px",
        display: "grid",
        placeItems: "center",
      }}
    >
      <img src={KhaltiIcon} alt="khalti-image" height={80} />
    </div> */}
      </PaymentWrapper>
      {/* <Button color="blue" style={{ marginTop: "50px" }}>
    CONTINUE
  </Button> */}
    </Wrapper>
  );
};
export default Payment;
