import { useEffect } from "react";
import ClassStudentPhotoImg from "src/common/images/class-student-photo.webp";
import StudentGroupPhoto from "src/common/images/student-group-photo.webp";
import UBTExamPhoto from "src/common/images/ubt-exam-img.webp";
import { RootWrapper } from "src/common/wrapper/root-wrapper";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { apiService } from "src/http/api-service";
import { A11y, Autoplay, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Pofile from "./profile";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { Body1 } from "src/common/theme";
import { Button } from "@mui/material";
import { NavWrapper } from "src/common/layouts/header/style";
import backgroundImg from "src/common/images/bgscreenImg.png";
import mobileApp from "src/common/images/mobileApp.png";
import googlePlay from "src/common/images/googlePlayButtonImg.png";
import appStore from "src/common/images/appstore.png";
import check from "src/common/images/check.png";

import { AngelAndroid, AngelIos } from "src/url";

function Index() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchSlider = async () => {
      const res = await apiService.getSlider(dispatch);
    };
    fetchSlider();
  }, []);

  const getSlider = useAppSelector((state) => state.slider.slider);

  return (
    <RootWrapper>
      <Container
        style={{
          backgroundImage: `url(${backgroundImg})`,
        }}
      >
        {/* <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          style={{
            width: "100%",
            objectFit: "cover",
          }}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          loop
        >
          {getSlider.length > 0 ? (
            getSlider.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="swiper-slide">
                    <div
                      style={{
                        width: "100%",
                        maxWidth: "1070px",
                        height: "100%",
                        maxHeight: "603px",
                        objectFit: "cover",
                        zIndex: 0,
                      }}
                    >
                      <img
                        src={item.image}
                        alt="images"
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })
          ) : (
            <>
              <SwiperSlide>
                <div
                  style={{
                    width: "100%",
                    maxWidth: "1070px",
                    height: "100%",
                    maxHeight: "603px",
                    objectFit: "cover",
                  }}
                >
                  <img
                    src={StudentGroupPhoto}
                    alt="images"
                    height="100%"
                    width="100%"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  style={{
                    width: "100%",
                    maxWidth: "1070px",
                    height: "100%",
                    maxHeight: "603px",
                    objectFit: "cover",
                  }}
                >
                  <img
                    src={UBTExamPhoto}
                    alt="images"
                    height="100%"
                    width="100%"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  style={{
                    width: "100%",
                    maxWidth: "1070px",
                    height: "100%",
                    maxHeight: "603px",
                    objectFit: "cover",
                  }}
                >
                  <img
                    src={ClassStudentPhotoImg}
                    alt="images"
                    height="100%"
                    width="100%"
                  />
                </div>
              </SwiperSlide>
            </>
          )}
        </Swiper>

        <Pofile /> */}
        <TemplateWrapper>
          <TemplateBox>
            <TemplateText
            // style={{ display: "flex", flexDirection: "column", gap: 10 }}
            >
              <Flex>
                <CheckImg src={check} alt="" />
                <div>
                  Free Books <br />
                </div>
              </Flex>
              <Flex>
                <CheckImg src={check} alt="" />
                Free UBT SETS <br />
              </Flex>
              <Flex>
                <CheckImg src={check} alt="" />
                Paid UBT SETS <br />
              </Flex>
              <Flex>
                <CheckImg src={check} alt="" />
                Online Classes <br />
              </Flex>
              <Flex>
                <CheckImg src={check} alt="" />
                Dictionary
              </Flex>
              <Flex>
                <CheckImg src={check} alt="" />
                News & Notice
              </Flex>
            </TemplateText>
            <DownloadNow>DOWNLOAD APP NOW</DownloadNow>
            <ButtonWrapper>
              <a href={AngelAndroid} target="_blank" rel="noreferrer">
                <Image src={googlePlay} alt="" />
              </a>

              <a href={AngelIos} target="_blank" rel="noreferrer">
                <Image src={appStore} alt="" />
              </a>
            </ButtonWrapper>
          </TemplateBox>
        </TemplateWrapper>
        <div>
          <MobileAppImage src={mobileApp} alt="" />
        </div>
      </Container>
    </RootWrapper>
  );
}

export default Index;

const TemplateWrapper = styled.div`
  width: 100%;
  @media (max-width: 850px) {
    margin-top: 2rem;
  }
`;

const Flex = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const CheckImg = styled.img`
  height: 30px;
  @media (max-width: 1000px) {
    height: 28px;
  }
  @media (max-width: 850px) {
    height: 20px;
  }
  @media (max-width: 450px) {
    height: 18px;
  }
`;
const TemplateBox = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  gap: 1rem;
  background-color: white;
  box-shadow: 15px 0px 3px -1px #7473ff, -15px 0px 3px -3px #7473ff;
  @media (max-width: 500px) {
    padding: 12px;
  }
`;

const TemplateText = styled.div`
  // font-weight: 800;
  // font-size: 2em;
  color: #5956e9;
  font-family: Kanit;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;

  // desktop medium
  @media (max-width: 1200px) {
    font-size: 28px;
    line-height: 44px;
  }

  @media (max-width: 1100px) {
    font-size: 26px;
    line-height: 41px;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
    line-height: 32px;
  }

  //mobile view
  @media (max-width: 600px) {
    font-size: 28px;
    line-height: 42px;
  }

  @media (max-width: 680px) {
    font-size: 26px;
    line-height: 38px;
  }

  @media (max-width: 550px) {
    font-size: 24px;
    line-height: 36px;
  }
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 450px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: 350px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media (max-width: 300px) {
    font-size: 12px;
    line-height: 24px;
  }
  @media (max-width: 200px) {
    font-size: 10px;
    line-height: 20px;
  }
`;

const DownloadNow = styled.div`
  font-family: Josefin Sans;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: start;

  // desktop medium
  @media (max-width: 1200px) {
    font-size: 22px;
  }

  @media (max-width: 1100px) {
    font-size: 24px;
  }

  @media (max-width: 1000px) {
    font-size: 20px;
  }

  // mobile view
  @media (max-width: 600px) {
    font-size: 28px;
  }

  @media (max-width: 680px) {
    font-size: 26px;
  }

  @media (max-width: 550px) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 20px;
  }
  @media (max-width: 450px) {
    font-size: 18px;
  }
  @media (max-width: 350px) {
    font-size: 16px;
  }
  @media (max-width: 300px) {
    font-size: 12px;
  }
  @media (max-width: 200px) {
    font-size: 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const Container = styled.div`
  display: flex;
  min-height: 650px;
  column-gap: 30px;
  margin: 0.5em 0;
  width: 100%;
  background-size: cover;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 850px) {
    top: 10px;
    flex-direction: column;
    height: fit-content;
  }
`;

const Image = styled.img`
  height: 44px;
  object-fit: "contain";

  // desktop medium
  @media (max-width: 1200px) {
    height: 38px;
  }

  @media (max-width: 1100px) {
    height: 36px;
  }

  @media (max-width: 1000px) {
    height: 30px;
  }
  //mobile view

  @media (max-width: 600px) {
    height: 42px;
  }

  @media (max-width: 680px) {
    height: 40px;
  }

  @media (max-width: 550px) {
    height: 38px;
  }
  @media (max-width: 500px) {
    height: 32px;
  }
  @media (max-width: 450px) {
    height: 28px;
  }
  @media (max-width: 350px) {
    height: 30px;
  }
  @media (max-width: 300px) {
    height: 20px;
  }
  @media (max-width: 200px) {
    height: 26px;
  }
`;

const MobileAppImage = styled.img`
  height: 500px;

  // desktop medium
  @media (max-width: 1200px) {
    height: 398px;
  }

  @media (max-width: 1100px) {
    height: 396px;
  }

  // mobile view
  @media (max-width: 600px) {
    height: 400px;
  }

  @media (max-width: 680px) {
    height: 430px;
  }

  @media (max-width: 550px) {
    height: 350px;
  }
  @media (max-width: 500px) {
    height: 300px;
  }
  @media (max-width: 450px) {
    height: 250px;
  }
  @media (max-width: 350px) {
    height: 200px;
  }
  @media (max-width: 300px) {
    height: 150px;
  }
  @media (max-width: 200px) {
    height: 100px;
  }
`;
